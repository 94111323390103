import { useMemo } from "react";

import {
  makeSelectSpecificCreator,
  selectCreatorLoading,
} from "selectors/creator";

import useReduxState from "hooks/useReduxState";

function useCreatorDataById(creatorId) {
  const selectSpecificCreator = useMemo(makeSelectSpecificCreator, []);

  const creator = useReduxState(
    (state) => selectSpecificCreator(state, creatorId),
    [creatorId]
  );

  return creator;
}

export function useCreatorDataIsLoading(creatorId) {
  const loading = useReduxState(
    (state) => selectCreatorLoading(state, creatorId),
    [creatorId]
  );

  return loading;
}

export default useCreatorDataById;
