import React from "react";
import { Link } from "react-router-dom";

import formatCategory from "utils/formatCategory";
import sendGAEvent from "utils/sendGAEvent";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  categories: {
    display: "flex",
    alignItems: "center",
    gap: "0.3rem",
  },
  text: {
    ...gStyles.avalonBold,
    fontSize: ".813rem",
    lineHeight: "16px",
    display: "block",
    color: colours.darkGrey,
    background: colours.neutral,
    padding: "0 8px",
    margin: 0,
    borderRadius: 100,
    width: "fit-content",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowContainer: {
    display: "none",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",
    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function PodcastCompactCategory(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId, context } = props;

  const podcast = usePodcastData(podcastId);
  const onCategoryClick = (category) => {
    sendGAEvent({
      action: "CategoryPillClick",
      podcast_id: podcastId,
      categoryTitle: category,
      context,
    });
  };

  const categories = podcast?.get("categories");

  if (!categories || categories.size === 0) {
    return null;
  }

  const categoriesToShow = categories.slice(0, 1);
  const hasMore = categories.size > 1;

  return (
    <section className={css(styles.categories)}>
      {categoriesToShow.map((category) => (
        <Link
          key={category}
          to={`/categories/${category}/podcasts`}
          className={css(styles.text)}
          onClick={() => onCategoryClick(formatCategory(category))}
        >
          {formatCategory(category)}
        </Link>
      ))}
      {hasMore && (
        <Link key="extra" to={`/categories`} className={css(styles.text)}>
          +{categories.size - 1}
        </Link>
      )}
    </section>
  );
}

export default PodcastCompactCategory;
