import { Link } from "react-router-dom";

import CreatorProfileHoverPopover from "components/Profiles/CreatorProfileHoverPopover";

import getCreatorUrl from "utils/entity/getCreatorUrl";

import useCreatorDataById from "hooks/useCreatorDataById";
import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  hosts: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
    width: "100%",
    overflow: "hidden",
    marginBottom: 5,
  },
  text: {
    ...gStyles.avalonBold,
    fontSize: ".813rem",
    lineHeight: "16px",
    display: "block",
    color: "var(--color-neutral-d5)",
    margin: 0,
    width: "max-content",
    whiteSpace: "noWrap",

    cursor: "pointer",

    ":hover": {
      color: colours.primary,
    },
    ":last-child": {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  label: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    lineHeight: "16px",
    display: "block",
    color: "var(--color-neutral-d5)",
    margin: 0,
    width: "max-content",
    whiteSpace: "noWrap",
  },
};

const CreatorHover = (props) => {
  const { children, creatorId } = props;
  const creator = useCreatorDataById(creatorId);

  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  return (
    <CreatorProfileHoverPopover mobile={mobile} creator={creator}>
      {children}
    </CreatorProfileHoverPopover>
  );
};

function PodcastCardHosts(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId, label } = props;

  const podcast = usePodcastData(podcastId);

  const listCreator = podcast
    ?.get("creator_with_roles")
    ?.toIndexedSeq()
    ?.toList();

  if (!listCreator || listCreator?.size === 0) {
    return null;
  }

  return (
    <section className={css(styles.hosts)}>
      <h4 className={css(styles.label)}>{label || "Hosted by"} </h4>
      {listCreator?.map((creator, index) => {
        const name = creator?.get("name");

        if (!name || !creator) {
          return null;
        }

        return (
          <CreatorHover key={creator?.get("id")} creatorId={creator?.get("id")}>
            <h4 className={css(styles.text)}>
              <Link to={getCreatorUrl(creator)}>
                {`${name}${index === listCreator?.size - 1 ? "" : ","}`}
              </Link>
            </h4>
          </CreatorHover>
        );
      })}
    </section>
  );
}

export default PodcastCardHosts;
