import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import InfoIcons from "components/Entities/Items/InfoIconsAsync";
import HeaderVerifiedBadge from "pages/PodcastView/HeaderVerifiedBadge";

import { getPodcastCardIcons } from "./PodcastCards/sharedPodcastElements";

import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  podcastInfoIconRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  rated: {
    background: "var(--color-neutral-d10)",
    border: "none",
    color: "var(--color-neutral-d15)",
    height: "1rem",
    width: "max-content",
    ...gStyles.avalonBold,
    borderRadius: 25,
    fontSize: 13,
    display: "flex",
    alignItems: "Center",
    gap: 5,
    marginLeft: "0.3rem",
  },
  star: {
    color: colours.primary,
    fontSize: 10.5,
  },
  marginRight: {
    marginRight: "0.3rem",
  },
};

const insidePanelStyles = {
  podcastDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
};

const noPanelStyles = {
  podcastDesktopCard: {
    ...cardStyles.cardNoPanel,
  },
  topRow: {
    paddingBottom: 0,
    marginBottom: 0,
    borderBottom: 0,
    [ScreenSizes.lgAndAbove]: {
      paddingBottom: 0,
      marginBottom: 0,
      borderBottom: 0,
    },
  },
};

const iconStyles = {
  iconContainer: {
    marginRight: ".75rem",
  },
};

const verifiedBadgeStyles = {
  verifiedBadge: {
    height: 17,
    width: "1rem",
    marginTop: "-0.2rem",
    marginLeft: "0.3rem",
    marginRight: "0.3rem",
    [ScreenSizes.lgAndAbove]: {
      height: 17,
      marginLeft: "0.3rem",
      marginRight: "0.3rem",
    },
  },
};

export default function PodcastCardIcons(props) {
  const {
    hideRating,
    hideEmptyRating,
    hideInfoIcons,
    podcast,
    onRateButtonClick,
    noPanel,
    insidePanel,
    inlineContent,
  } = props;

  const { styles } = useStyles(
    [baseStyles, noPanel && noPanelStyles, insidePanel && insidePanelStyles],
    props
  );

  const icons = useMemo(() => getPodcastCardIcons(podcast), [podcast]);

  const hasPro = useUserHasPro();
  const showIsVerified = hasPro;

  const showingRating =
    !hideRating && !(hideEmptyRating && podcast.get("rating_count") === 0);
  const showingInfoIcons = !hideInfoIcons && icons;

  const rating = podcast?.get("rating") || podcast?.get("display_rating");

  const numRatings = podcast?.get("rating_count");
  const formattedRating = Math.round(rating * 10) / 10;

  const handleRate = useMemo(
    () => onRateButtonClick || (() => null),
    [onRateButtonClick]
  );

  const renderTooltip = useCallback(() => {
    if (rating) {
      return `Rated ${formattedRating} from ${numRatings} votes`;
    }

    return "";
  }, [numRatings, rating, formattedRating]);

  if (!showingInfoIcons && !showingRating) {
    return null;
  }

  return (
    <div className={css(styles.podcastInfoIconRow)}>
      {showingInfoIcons && (
        <InfoIcons podcast={podcast} icons={icons} styles={iconStyles} />
      )}
      {showIsVerified && (
        <HeaderVerifiedBadge styles={verifiedBadgeStyles} podcast={podcast} />
      )}
      {formattedRating > 0 && (
        <BasicTooltip renderTooltip={renderTooltip}>
          {(tooltipProps) => (
            <button
              data-testid="addToList"
              className={css(styles.rated, inlineContent && styles.marginRight)}
              {...tooltipProps}
              onClick={handleRate}
            >
              <span className={css(styles.star)}>
                <FontAwesomeIcon icon={faStar} dataid="addToList" />
              </span>
              {formattedRating}
            </button>
          )}
        </BasicTooltip>
      )}
      {inlineContent}
    </div>
  );
}

PodcastCardIcons.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  onRateButtonClick: PropTypes.func,
  buttons: PropTypes.node,
  actions: PropTypes.array,
  hideBottomRow: PropTypes.bool,
  noPanel: PropTypes.bool,
  hideInfoIcons: PropTypes.bool,
  hideRating: PropTypes.bool,
  hideEmptyRating: PropTypes.bool,
  hideSecondaryButtons: PropTypes.bool,
  insidePanel: PropTypes.bool,
  hoverableStars: PropTypes.bool,
  clickableStars: PropTypes.bool,
  renderEntityImageOverlay: PropTypes.func,
  hidePowerScore: PropTypes.bool,
};

PodcastCardIcons.defaultProps = {
  podcast: null,
  onRateButtonClick: null,
  buttons: null,
  actions: null,
  hideBottomRow: false,
  noPanel: false,
  insidePanel: false,
  hideInfoIcons: false,
  hideRating: false,
  hideEmptyRating: true,
  hideSecondaryButtons: false,
  hoverableStars: false,
  clickableStars: false,
  renderEntityImageOverlay: null,
  hidePowerScore: false,
};
