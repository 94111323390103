import React from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";

import gStyles from "../../../../styles/GenericStyles";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  container: {
    ...gStyles.fontRegular,
    display: "flex",

    alignItems: "center",
    justifyItems: "Center",

    textAlign: "center",

    fontSize: "0.75rem",

    padding: "0.5rem",
  },
  children: {
    width: "100%",
  },
};

export default function ToolTipValue(props) {
  const { children, title } = props;

  const { styles, css } = useStyles(baseStyles, props);

  return (
    <span className={css(styles.container)}>
      <BasicTooltip renderTooltip={() => title} zIndex={99999} forceOpen={true}>
        {(tooltipProps) => (
          <span className={css(styles.children)} {...tooltipProps}>
            {children}
          </span>
        )}
      </BasicTooltip>
    </span>
  );
}
