import { useMemo } from "react";

import PodcastCardCategories from "./PodcastCardCategories";
import PodcastCardIcons from "./PodcastCardIcons";

import useWindowSize from "hooks/useWindowSize";

export default function PodcastCardStats(props) {
  const { podcast } = props;

  const { isWindowWidthOrLessNumeric } = useWindowSize();

  const isMediumOrLess = isWindowWidthOrLessNumeric(1115);

  const inlineContent = useMemo(() => {
    if (isMediumOrLess) {
      return <PodcastCardCategories podcastId={podcast?.get("id")} />;
    }

    return null;
  }, [isMediumOrLess, podcast]);

  return <PodcastCardIcons {...props} inlineContent={inlineContent} />;
}
