export default function formatCategory(unformattedCategory) {
  if (typeof unformattedCategory === "string") {
    const categoryWithoutPostfix = unformattedCategory
      .split("-category")[0]
      ?.replace("-", " ");

    return categoryWithoutPostfix.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return unformattedCategory;
}
