import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import StandardButton from "components/Buttons/StandardButton";
import AddToList from "components/Common/table/commons/AddToList";
import EntityImage from "components/Entities/EntityImage";

import PodcastCardAudience from "../Entities/Cards/PodcastCardAudience";
import PodcastCardHosts from "../Entities/Cards/PodcastCardHosts";
import PodcastCardNetworkAndAuthor from "../Entities/Cards/PodcastCardNetworkAndAuthor";
import PodcastCardStats from "../Entities/Cards/PodcastCardStats";
import PodcastCompactCategory from "../Entities/Cards/PodcastCompactCategory";
import EntityLink from "../Entities/EntityLink";
import profileHoverCardStyles from "./ProfileHoverCardStyles";
import ProfileHoverPopoverContent from "./ProfileHoverPopoverContent";

import podcastActions from "actions/podcast";
import {
  selectSpecificPodcast,
  selectPodcastLoading,
  selectPodcastFailed,
} from "selectors/podcast";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { capitalize } from "utils/misc";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useLoadEntity from "hooks/useLoadEntity";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const mobileImageSize = "4.375rem";
const desktopImageSize = "4.375rem";

const baseStyles = {
  ...profileHoverCardStyles,
  content: {
    ...profileHoverCardStyles.content,
    textAlign: "center",
  },
  topInfoRow: {
    ...profileHoverCardStyles.topInfoRow,
    flexDirection: "column",
    alignItems: "start",
    gap: "1rem",
    padding: "1rem",
  },
  profileImage: {
    ...profileHoverCardStyles.profileImage,
    marginBottom: "0",
    marginRight: "1rem",
    width: mobileImageSize,
    height: mobileImageSize,

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.profileImage[ScreenSizes.lgAndAbove],
      marginRight: "1rem",
      marginBottom: "0",
      width: desktopImageSize,
      height: desktopImageSize,
    },
  },
  names: {
    marginBottom: "1rem",
  },
  name: {
    ...profileHoverCardStyles.name,
    marginBottom: ".75rem",
    display: "block",

    [ScreenSizes.lgAndAbove]: {
      ...profileHoverCardStyles.name[ScreenSizes.lgAndAbove],
      marginBottom: ".5rem",
      fontSize: "1rem",
    },
  },
  rating: {
    marginBottom: "0.5rem",
    fontSize: "0.875rem",
  },

  topRow: {
    display: "flex",
    flexDirection: "row",
  },
  description: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    overflow: "hidden",
    fontSize: "0.875rem",
    textAlign: "start",
  },
  topRightWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "0.75rem",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    height: 32,
    marginBottom: "0.5rem",
  },
  statsAndCategoryWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "0.3rem",
  },

  skeletonContainer: {
    padding: "1rem",
  },
  skeletonRow: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    marginBottom: "1rem",
  },
  skeletonColumn: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    width: "100%",
  },
  skeletonBlock: {
    backgroundColor: "#eee",
    borderRadius: "4px",
  },
  skeletonImage: {
    ...gStyles.skeletonPlaceholder,
    width: "4.375rem",
    height: "4.375rem",
    backgroundColor: "#eee",
    borderRadius: "8px",
  },
  skeletonTitle: {
    ...gStyles.skeletonPlaceholder,
    width: "30%",
    height: "1rem",
  },
  skeletonSubTitle: {
    ...gStyles.skeletonPlaceholder,
    width: "40%",
    height: "0.875rem",
  },
  skeletonStats: {
    width: "60%",
    height: "0.875rem",
  },
  skeletonLine: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "0.75rem",
    marginTop: "0.25rem",
  },
  skeletonButtonRow: {
    display: "flex",
    gap: "1rem",
    marginTop: "0.5rem",
  },
  skeletonButton: {
    ...gStyles.skeletonPlaceholder,
    width: "50%",
    height: "2rem",
    backgroundColor: "#eee",
    borderRadius: 25,
    justifyContent: "center",
  },
};

const POPOVER_STYLES = {
  desktopContent: {
    width: "27.813rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
};

const HOST_CARD_STYLES = {
  label: {
    color: colours.black,
    marginRight: "4.35rem",
  },
  text: {
    ...gStyles.fontRegular,
    fontSize: 12,
  },
};

const ENTITY_LINK_STYLES = {
  link: {
    textAlign: "start",
    marginBottom: 0,
  },
};

const ADD_TO_LIST_STYLES = {
  onList: {
    width: "100%",
    height: 32,
    justifyContent: "center",
    background: "var(--color-primary-d10)",
    border: "none",
  },
  addToListIcon: {
    width: "100%",
    justifyContent: "center",
  },
  full: {
    width: "100%",
    justifyContent: "center",
  },
};

const imagestyles = {
  entityImage: {
    width: "4.375rem",
    height: "4.375rem",
  },
};

const PodcastProfileHoverCard = (props) => {
  const { mobile, width, podcast_id, popoutProps } = props;
  const { styles } = useStyles(baseStyles, props);

  const hasPro = useUserHasPro();

  const { loadSpecificPodcast } = useActionCreators({
    loadSpecificPodcast: podcastActions.loadSpecificPodcast,
  });

  const { entity: podcast, loading } = useLoadEntity({
    entity_type: "podcast",
    entity_id: podcast_id,
    loader: loadSpecificPodcast,
    selector: selectSpecificPodcast,
    loadingSelector: selectPodcastLoading,
    failedSelector: selectPodcastFailed,
  });

  const handleCTAClick = () => {
    sendGAEvent({
      action: "ViewInsightCTAClick",
      context: "ViewInsightCTAPodcastHoverCard",
      podcast_id: podcast.get("id"),
      podcast_name: podcast.get("title"),
    });
  };

  // TODO: This is a bit of a hack to 'fix' the text-based categories, just to get this working quickly.
  const podcastWithCategories = useMemo(() => {
    if (podcast) {
      return podcast.set(
        "categories",
        podcast.get("categories")
          ? podcast.get("categories").map((category) => {
              if (typeof category === "string") {
                return new Map([
                  [
                    "text",
                    category &&
                      category
                        .split("-")
                        .map((word) => capitalize(word))
                        .join(" ")
                        .replace(" Category", ""),
                  ],
                  ["slug", category],
                ]);
              }
              if (typeof category === "object") {
                return category;
              }
              return new Map([]);
            })
          : new Map([])
      );
    }
  }, [podcast]);

  const renderInfo = useCallback(
    () => (
      <div className={css(styles.topInfoRow)}>
        <div className={css(styles.topRow)}>
          <div className={css(styles.profileImage)}>
            <EntityImage
              entity={podcastWithCategories}
              entity_type="podcast"
              alt={podcast.get("title")}
              size={width}
              disablePopup
              fullWidth
              styles={imagestyles}
            />
          </div>

          <div className={css(styles.topRightWrapper)}>
            <EntityLink
              entity={podcast}
              entity_type="podcast"
              clamp={2}
              disablePopup
              avalon
              showClaimedBadge
              styles={ENTITY_LINK_STYLES}
            />

            <PodcastCardNetworkAndAuthor podcast={podcast} />
            <div className={css(styles.statsAndCategoryWrapper)}>
              <PodcastCardStats podcast={podcast} />
              <PodcastCompactCategory podcastId={podcast?.get("id")} />
            </div>
          </div>
        </div>

        <div className={css(styles.description)}>
          {podcast.get("description")}
        </div>

        {hasPro && <PodcastCardAudience podcastId={podcast?.get("id")} />}

        <PodcastCardHosts
          styles={HOST_CARD_STYLES}
          label="Hosts"
          podcastId={podcast?.get("id")}
        />
      </div>
    ),
    [
      podcast,
      podcastWithCategories,
      styles.description,
      styles.profileImage,
      styles.topInfoRow,
      styles.statsAndCategoryWrapper,
      styles.topRightWrapper,
      styles.topRow,
      hasPro,
      width,
    ]
  );
  if (loading) {
    return (
      <ProfileHoverPopoverContent
        styles={POPOVER_STYLES}
        popoutProps={popoutProps}
      >
        <div className={css(styles.skeletonContainer)}>
          <div className={css(styles.skeletonRow)}>
            <div className={css(styles.skeletonImage, styles.skeletonBlock)} />
            <div className={css(styles.skeletonColumn)}>
              <div
                className={css(styles.skeletonTitle, styles.skeletonBlock)}
              />
              <div
                className={css(styles.skeletonSubTitle, styles.skeletonBlock)}
              />
              <div
                className={css(styles.skeletonStats, styles.skeletonBlock)}
              />
            </div>
          </div>

          <div className={css(styles.skeletonLine, styles.skeletonBlock)} />
          <div className={css(styles.skeletonLine, styles.skeletonBlock)} />

          <div className={css(styles.skeletonButtonRow)}>
            <div className={css(styles.skeletonButton)} />
            <div className={css(styles.skeletonButton)} />
          </div>
        </div>
      </ProfileHoverPopoverContent>
    );
  }

  if (!podcast && !loading) {
    return null;
  }

  return (
    <ProfileHoverPopoverContent
      styles={POPOVER_STYLES}
      popoutProps={popoutProps}
    >
      <span className={css(styles.content, mobile && styles.mobileContent)}>
        {renderInfo()}
        <div className={css(styles.buttonContainer)}>
          <AddToList
            entity={podcast}
            entityId={podcast?.get("id")}
            entityType={"podcast"}
            withLabel
            styles={ADD_TO_LIST_STYLES}
          />

          <StandardButton
            label="View Insights"
            variation="pink"
            onClick={handleCTAClick}
            to={getPodcastUrl(podcast, "/insights")}
            link
            flat
          />
        </div>
      </span>
    </ProfileHoverPopoverContent>
  );
};

PodcastProfileHoverCard.propTypes = {
  popoutProps: PropTypes.object.isRequired,
  podcast_id: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
  width: PropTypes.number,
};

PodcastProfileHoverCard.defaultProps = {
  mobile: false,
  width: 70,
};

export default memo(PodcastProfileHoverCard);
