import Flags from "country-flag-icons/react/3x2";

import { countryItemValue } from "pages/PodcastView/Insights/Sections/InsightsDemographics/InsightsMapChart";

import ToolTipValue from "./ToolTipValue";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";

const baseStyles = {
  center: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
};

const flagStyle = { width: 20, height: 20, marginRight: "0.5rem" };

export const sortByPercentageDescending = (a, b) => {
  const percentageA = a.get("percentage");
  const percentageB = b.get("percentage");

  if (percentageA > percentageB) {
    return -1;
  }
  if (percentageA < percentageB) {
    return 1;
  }

  return 0;
};

export default function TopCountry(props) {
  const { podcastId } = props;

  const { styles, css } = useStyles(baseStyles, props);

  const podcast = usePodcastData(podcastId);

  if (!podcast) {
    return null;
  }

  if (!podcast.hasIn(["user_data", "pro_data", "top_countries"])) {
    return <span className={css(styles.center)}>-</span>;
  }

  const topCountry = podcast
    .getIn(["user_data", "pro_data", "top_countries"])
    ?.sort(sortByPercentageDescending)
    .get(0);

  const topCountryPercentage = topCountry.get("percentage") * 100;
  const topCountryCode = topCountry.get("code");

  const Flag = Flags[topCountryCode];

  return (
    <ToolTipValue title={countryItemValue(topCountryPercentage)}>
      <>
        <Flag style={flagStyle} /> {topCountryCode}
      </>
    </ToolTipValue>
  );
}
