import PropTypes from "prop-types";
import { Children, cloneElement, useCallback, useRef } from "react";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";

import ScreenSizes from "styles/ScreenSizes";

const popoutStyles = {
  contentWrapperOuter: {
    zIndex: 1200,
  },
  contentWrapper: {
    zIndex: 1200,
    padding: "1.5rem",

    [ScreenSizes.lgAndAbove]: {
      padding: ".75rem",
    },
  },
};

const popoutStylesExpanded = {
  ...popoutStyles,
  container: {
    width: "100%",
  },
};

const ProfileHoverPopover = (props) => {
  const { renderContent, children, mobile, inline, expand, ariaLabel } = props;
  const hovertimeout = useRef(null);
  const closetimeout = useRef(null);

  const handleDesktopHover = useCallback(
    (openMenu) => () => {
      if (closetimeout.current) {
        clearTimeout(closetimeout.current);
        closetimeout.current = null;
      }
      if (!hovertimeout.current) {
        hovertimeout.current = setTimeout(() => {
          openMenu();
          hovertimeout.current = null;
        }, 300);
      }
    },
    []
  );

  const handleDesktopEndHover = useCallback(
    (closeMenu, isOpen) => () => {
      if (hovertimeout.current) {
        clearTimeout(hovertimeout.current);
        hovertimeout.current = null;
      }
      if (isOpen) {
        closetimeout.current = setTimeout(() => {
          closeMenu();
          closetimeout.current = null;
        }, 200);
      }
    },
    []
  );

  const handleContentEndHover = useCallback(
    (closeMenu) => () => {
      if (hovertimeout.current) {
        clearTimeout(hovertimeout.current);
        hovertimeout.current = null;
      }

      closetimeout.current = setTimeout(() => {
        closeMenu();
        closetimeout.current = null;
      }, 500);
    },
    []
  );

  const handleContentHover = useCallback(() => {
    if (closetimeout.current) {
      clearTimeout(closetimeout.current);
      closetimeout.current = null;
    }
  }, []);

  const renderProfileContent = useCallback(
    (popoutProps) =>
      renderContent({
        ...popoutProps,
        onContentHover: handleContentHover,
        onContentEndHover: handleContentEndHover(
          popoutProps.contentProps.closeMenu
        ),
      }),
    [renderContent, handleContentHover, handleContentEndHover]
  );

  const renderProfileButton = useCallback(
    (popoutProps) => {
      const { openMenu, closeMenu, isOpen, ref } = popoutProps;
      let elements = Children.toArray(children);
      const firstElement = elements[0];

      const newProps = {
        ref,
        onMouseEnter: handleDesktopHover(openMenu),
        onMouseLeave: handleDesktopEndHover(closeMenu, isOpen),
      };

      elements = [cloneElement(firstElement, newProps)].concat(
        elements.slice(1, -1)
      );

      return elements;
    },
    [handleDesktopHover, handleDesktopEndHover, children]
  );

  return (
    <ButtonWithPopout
      renderButton={renderProfileButton}
      renderContent={renderProfileContent}
      placement="top"
      positionFixed={mobile}
      inline={inline}
      expand={expand}
      styles={expand ? popoutStylesExpanded : popoutStyles}
      ariaLabel={ariaLabel || "Show Profile Popover"}
      customPopout
    />
  );
};

ProfileHoverPopover.propTypes = {
  renderContent: PropTypes.func.isRequired,
  children: PropTypes.node,
  mobile: PropTypes.bool,
  inline: PropTypes.bool,
  expand: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

ProfileHoverPopover.defaultProps = {
  children: null,
  mobile: false,
  inline: false,
  expand: false,
  ariaLabel: undefined,
};

export default ProfileHoverPopover;
