import Highlighter from "react-highlight-words";

import rawTokens from "../../styles/tokens/tokens-raw";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  highlightedWord: {
    backgroundColor: rawTokens.raw.color.yellow.l80,
    borderRadius: 3,
    padding: "0 2px",
  },
  secondaryHighlightedWord: {
    background: rawTokens.raw.color.violet.l90,
    borderRadius: 3,
    padding: "0 2px",
  },
};

function convertTextToArrayBySpace(word) {
  if (!word) {
    return [];
  }

  let listOfWords;

  if (word.includes(" ")) {
    listOfWords = word
      ?.split(" ")
      .concat(word)
      .filter((elem) => !!elem)
      .map((elem) => elem.replace("  ", " "));
  } else {
    listOfWords = [word];
  }

  const listOfWordsIncludingDots = listOfWords.reduce((acc, word) => {
    const wordWithDots = word.split("").join("\\.*").concat("\\.*");

    return acc.concat(word, wordWithDots);
  }, []);

  return listOfWordsIncludingDots;
}

function removeBooleanSearchCharacters(word) {
  if (word) {
    return word.replace(/["~|+()-]/g, "");
  }

  return "";
}

function HighLightText(props) {
  const { principalSearchTerm, secondarySearchTerm, textToHighlight } = props;
  const { styles, css } = useStyles(baseStyles, props);

  const principalSearchList = convertTextToArrayBySpace(
    removeBooleanSearchCharacters(principalSearchTerm)
  );
  const secondarySearchList = convertTextToArrayBySpace(
    removeBooleanSearchCharacters(secondarySearchTerm)
  );

  const searchWords = principalSearchList
    .concat(secondarySearchList)
    .filter((elem) => !!elem);

  const Highlight = (props) => {
    const { children } = props;

    const isSecondaryTerm =
      secondarySearchTerm &&
      secondarySearchTerm?.toLowerCase()?.includes(children?.toLowerCase());

    if (isSecondaryTerm) {
      return (
        <span className={css(styles.secondaryHighlightedWord)}>{children}</span>
      );
    }

    return <span className={css(styles.highlightedWord)}>{children}</span>;
  };

  return (
    <Highlighter
      highlightClassName={css(styles.highlightedWord)}
      searchWords={searchWords}
      textToHighlight={textToHighlight}
      highlightTag={Highlight}
    />
  );
}

export default HighLightText;
