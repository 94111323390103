import React from "react";
import { Link } from "react-router-dom";

import formatCategory from "utils/formatCategory";
import sendGAEvent from "utils/sendGAEvent";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  categories: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
  },
  text: {
    ...gStyles.avalonBold,
    fontSize: ".813rem",
    lineHeight: "16px",

    display: "block",

    color: colours.darkGrey,
    background: colours.neutral,

    padding: "0 8px",

    margin: 0,

    borderRadius: 100,

    width: "fit-content",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowContainer: {
    display: "none",

    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
      display: "grid",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
};

function PodcastCardCategories(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId, episodeId, context } = props;

  const { isWindowSizeOrLess } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("large");

  const podcast = usePodcastData(podcastId);
  const onCategoryClick = (category) => {
    sendGAEvent({
      action: "CategoryPillClick",
      podcast_id: podcastId,
      categoryTitle: category,
      context,
      ...(episodeId && { episode_id: episodeId }),
    });
  };

  const compactMode = isMediumOrLess;

  const categories = podcast?.get("categories");
  const categoriesToShow = compactMode
    ? categories?.slice(0, 1)
    : categories?.slice(0, 3);

  const hasMore = compactMode && categories?.size - 1 > 0;

  if (!categories || categories?.size === 0) {
    return null;
  }

  return (
    <div className={css(styles.bottomRowContainer)}>
      <h3 className={css(styles.bottomRowLabel)}>Categories</h3>
      <section className={css(styles.categories)}>
        {categoriesToShow.map((category) => (
          <Link
            key={category}
            to={`/categories/${category}/podcasts`}
            className={css(styles.text)}
            onClick={() => onCategoryClick(formatCategory(category))}
          >
            {formatCategory(category)}
          </Link>
        ))}
        {hasMore && (
          <Link key={"extra"} to={`/categories`} className={css(styles.text)}>
            +{categories?.size - 1}
          </Link>
        )}
      </section>
    </div>
  );
}

export default PodcastCardCategories;
