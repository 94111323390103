import { faLock } from "@fortawesome/pro-regular-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flags from "country-flag-icons/react/3x2";
import React, { useCallback } from "react";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import ProCircleIcon from "components/Pro/ProCircleIcon";
import {
  getDemographicSkewTitle,
  getAgeRange,
} from "pages/PodcastView/Insights/Sections/InsightAudience";
import InsightsGenderColorLabel from "pages/PodcastView/Insights/Sections/InsightsGenderColorLabel";

import ToolTipValue from "../../Common/table/commons/ToolTipValue";
import { sortByPercentageDescending } from "../../Common/table/commons/TopCountry";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  audience: {
    display: "flex",
    alignItems: "Center",
    gap: "0.3rem",
  },
  flagContainer: {
    padding: "0 0.5rem",
    background: "var(--color-neutral-d10)",
    borderRadius: 25,
    height: "1rem",
    display: "grid",
    placeItems: "center",
  },
  age: {
    padding: "0 0.5rem",
    background: "var(--color-neutral-d10)",
    borderRadius: 25,
    height: "1rem",
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
  },
  lock: {
    ...gStyles.fontRegular,
    fontSize: 13,
    color: colours.secondary,
    outline: "none",
    border: "none",
    display: "flex",
    gap: 4,
    alignItems: "center",
    textWrap: "wrap",
    padding: 0,
    textAlign: "start",

    ":hover": {
      cursor: "pointer",
    },
  },
  bottomRowLabel: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    margin: 0,
    display: "flex",
    gap: "0.5rem",
  },
  bottomRowContainer: {
    display: "grid",
    gridTemplateColumns: "90px 1fr",
    gridGap: "1rem",
    alignItems: "center",
    maxWidth: "100%",

    [createBreakpoint({ min: 1115 })]: {
      maxWidth: 370,
    },
  },
  proContainer: {
    gridTemplateColumns: "65px 1fr",

    [ScreenSizes.mdAndAbove]: {
      gridTemplateColumns: "90px 1fr",
    },
  },
};

const audienceStyles = {
  label: {
    height: "1rem",
    minHeight: "1rem",
    fontSize: "0.813rem",
  },
  container: {
    padding: 0,
  },
};

const flagStyle = { width: 16, height: 14 };

function PodcastCardAudience(props) {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId } = props;

  const { getWindowWidth } = useWindowSize();
  const screenWidth = getWindowWidth();
  const isMediumOrLess = screenWidth <= 1115;

  const podcast = usePodcastData(podcastId);

  const genderScore =
    podcast?.getIn(["user_data", "pro_data", "gender_distribution_bias"]) * 100;

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const hasPro = useUserHasPro();

  const value = podcast?.getIn([
    "user_data",
    "pro_data",
    "gender_distribution_value",
  ]);

  const ageSkew = podcast?.getIn(["user_data", "pro_data", "age_median"]);
  const title = getDemographicSkewTitle({
    genderScore,
    value,
  });

  const topCountry = podcast
    ?.getIn(["user_data", "pro_data", "top_countries"])
    ?.sort(sortByPercentageDescending)
    ?.get(0);

  const topCountryCode = topCountry?.get("code", "");
  const topCountryTitle = topCountry?.get("title");

  const Flag = Flags[topCountryCode];

  const ageRange = getAgeRange(ageSkew);

  const ageRangeTooltip = `Our estimate of how old the average listener of this podcast is, ${Math.round(
    ageSkew
  )}.`;

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: `podcastCardUnlockReachButtonClicked`,
      entity_type: "Podcasts",
      context: "ProLeadForm",
    });
  }, []);
  const handleClick = () => {
    sendGAEvent({
      action: "openedProLeadFormModal",
      entity_type: "Podcast",
      context: "ProLeadForm",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  };

  if (!hasPro) {
    return (
      <div className={css(styles.bottomRowContainer)}>
        <h3 className={css(styles.bottomRowLabel)}>
          {!hasPro && isMediumOrLess ? "Insights" : "Audience"}
          <ProCircleIcon
            width=".5rem"
            height=".5rem"
            top="4px"
            right="auto"
            tooltipPlacement="bottom"
            tooltip="Pro Only"
            thin
            withTooltip
          />
        </h3>
        <button className={css(styles.lock)} onClick={handleClick}>
          <FontAwesomeIcon icon={faLock} data-testid="lock" dataid="lock" />
          {!hasPro && isMediumOrLess
            ? "Audience data, contacts and more"
            : "Reach and listener demographics"}
        </button>
      </div>
    );
  }

  if (!value && !ageRange && !topCountryCode) {
    return null;
  }

  return (
    <div
      className={css(styles.bottomRowContainer, !hasPro && styles.proContainer)}
    >
      <h3 className={css(styles.bottomRowLabel)}>
        {!hasPro && isMediumOrLess ? "Insights" : "Audience"}
        <ProCircleIcon
          width=".5rem"
          height=".5rem"
          top="4px"
          right="auto"
          tooltipPlacement="bottom"
          tooltip="Pro Only"
          thin
          withTooltip
        />
      </h3>
      <section className={css(styles.audience)}>
        {value && (
          <ToolTipValue styles={audienceStyles} title={title || ""}>
            <InsightsGenderColorLabel
              styles={audienceStyles}
              genderScore={genderScore}
              genderValue={value}
            />
          </ToolTipValue>
        )}
        {ageRange && (
          <ToolTipValue styles={audienceStyles} title={ageRangeTooltip}>
            <div className={css(styles.age)}>{ageRange}</div>
          </ToolTipValue>
        )}
        {topCountryCode && (
          <BasicTooltip
            renderTooltip={() => (
              <span>
                <b>{topCountryTitle}</b>: Top country where this podcast's
                audience resides
              </span>
            )}
          >
            {(tooltipProps) => (
              <div {...tooltipProps} className={css(styles.flagContainer)}>
                {topCountryCode && <Flag style={flagStyle} />}
              </div>
            )}
          </BasicTooltip>
        )}
      </section>
    </div>
  );
}

export default PodcastCardAudience;
